import {LoginPageComponent} from '../login-page/login-page.component';
import { RegisterComponent } from '../register/register.component';
import { Config } from '../config/config';
import { Routes } from '@angular/router';

export class RoutersMain {
  public static appRoutes: Routes = [
    { path: '', redirectTo: Config.router.login, pathMatch: 'full' },
    {
      path: Config.router.login,
      component: LoginPageComponent
    },
    {
      path: Config.router.register,
      component: RegisterComponent
    },
  ];
}

