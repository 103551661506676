import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from '../model/userModel';
import { Company } from '../model/companyModel';
import { Category } from '../model/category';
import { ValidationPassword } from '../services/validations.services';
import { UsersService } from '../services/user.services';
import { CompanyService } from '../services/company.service';
import { AuthService } from '../services/auth.services';
import { CountryService } from '../services/country';
import { CategoryService } from '../services/categories.services';


const buyerArray = [
  {name: 'user_type', active: true},
  {name: 'user_data', active: false},
  {name: 'end', active: false},
];

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../styles/main.scss'],
  providers: [UsersService, CompanyService, AuthService, CategoryService, CountryService]
})

export class RegisterComponent implements OnInit {
  wirzadNumber: any;
  selectedWirzad: number;
  user: User;
  selectSupplier: any;
  view: any;
  userRegister: NgForm;
  companyRegister: NgForm;
  company: Company;
  planSelected: number;
  zxcvbnPassword: number;
  plans: any;
  capitalLetters: boolean;
  passwordNumber: boolean;
  passwordSimbol: boolean;
  lowerCasePass: boolean;
  userData: User;
  categories: Category;
  countries: any;
  cities: any;
  constructor(private validationPass: ValidationPassword,
              private usersService: UsersService,
              private companyService: CompanyService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private countryService: CountryService) {
    this.wirzadNumber = buyerArray;
    this.selectedWirzad = 0;
    this.capitalLetters = false;
    this.passwordNumber = false;
    this.passwordSimbol = false;
    this.plans = [
      {
        id: '1',
        name: 'free',
        description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
        incididunt, Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt`,
        price: '500',
        type: 'plan mes',
        modules: [
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
        ]
      },
      {
        id: '2',
        name: 'basico',
        description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt,
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt`,
        price: '500',
        type: 'plan mes',
        modules: [
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
        ]
      },
      {
        id: '3',
        name: 'Stardard',
        description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt,
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt`,
        price: '500',
        type: 'plan mes',
        modules: [
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
        ]
      },
      {
        id: '4',
        name: 'Premium',
        description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt,
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt`,
        price: '500',
        type: 'plan mes',
        modules: [
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
          {name: '5 Modulos activos'},
        ]
      },
    ];
  }

  ngOnInit() {
    this.categoryService.get().subscribe((data) => {
      this.categories = data.data;
    });
    this.countryService.getCountries().subscribe((data) => {
      this.countries = data.data;
    });
    this.user = new User();
    this.company = new Company();
  }
  getCities(id) {
    this.countryService.getCities(id).subscribe((data) => {
      this.cities = data.data.cities;
    });
  }
  validatePassword(string, regExp) {
    return string.match(regExp);
  }
  uppercase(str) {
    return (str) ? str.toLowerCase() : str;
  }
  selectType(select) {
    this.selectSupplier = select;
    if (select === 2) {
      this.wirzadNumber = [
        {name: 'user_type', active: true},
        {name: 'user_data', active: false},
        {name: 'enterprise_data', active: false},
        {name: 'plans', active: false},
        {name: 'end', active: false},
      ];
    } else {
      this.wirzadNumber = buyerArray;
    }
  }
  activeWirzad(i) {
    this.selectedWirzad = i;
    this.wirzadNumber.map((obj, index) => {
      if (index === i) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    });
  }
  createCompany(value) {
    value.value.userUuid = this.userData.uuid;
    value.value.supplier = 1;
    // this.companyService.create(value.value).subscribe((data) => {
    //   console.log(data);
    // });
  }
  CreateUser(value) {
    // this.usersService.register(value.value).subscribe((data: any) => {
    //   this.userData = data.data.user;
    // });
  }
  selectPlan(plan) {
    this.planSelected = plan;
  }
  validationPassword(password) {
    this.validationPass.validationPassword(password).then((validations: any) => {
      this.zxcvbnPassword = validations.passwordValidation;
      this.capitalLetters = validations.capitalLetters;
      this.lowerCasePass = validations.LowerCase;
      this.passwordNumber = validations.passwordNumber;
      this.passwordSimbol = validations.passwordSimbol;
    })
  }

}
