import { Attachment } from './Attachment';
import { Category } from './category';
import { Supplier } from './supplier';
import { User } from './userModel';
import { Model } from './Model';

export class Company extends Model {
  public id: number;
  public uuid: string;
  public companyCode: string;
  public businessName: string;
  public rut: string;
  public address: string;
  public email: string;
  public phone: string;
  public whatsapp: string;
  public website: string;
  public contact: string;
  public contactPosition: string;
  public websiteSale: number;
  public createdYear: string | Date;
  public numberWorkers: number;
  public ancestorSale: number;
  public pastSale: number;
  public certifications: string;
  public productCatalog: string;
  public priceList: string;
  public financialInformationLastIVA: string;
  public legalInformationStatutes: string;
  public color: string;
  public supplier: number;
  public isDeleted: boolean;
  public createdAt: string | Date;
  public suppliers: Array<Supplier>;
  public users: Array<User>;
  public userUuid: string;
  public categoryId: number;
  public cityId: number;
  public country: number;
  public logo: Attachment;
  public legalInformation: Attachment;
}
