export class Config {
  public static api = 'http://localhost:3000/';
  public static endpoints = {
      login: 'auth/login',
      register: 'auth/register',
      createCompany: 'companies/my-company',
      categories: 'categories',
      countries: 'countries/'
  };
  public static token = 'token';
  public static localName = 'token';
  public static router = {
      main: '/',
      login: 'login',
      register: 'register',
      createProduct: 'createProduct'
  };
}
