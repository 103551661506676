import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient
  ) { }

  login(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.login, data);
  }

}
