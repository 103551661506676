export class Model {
  constructor(partial?: Partial<Model>) {
    if (partial) {
      return this.load(partial);
    }
  }

  load(partial: Partial<Model>) {
    return Object.assign(this, partial);
  }
}
