import { Injectable } from '@angular/core';
import * as zxcvbn from 'zxcvbn';

@Injectable()
export class ValidationPassword {
    numbers: any;
  constructor() {}
  validationPassword(password) {
    const validations = {
        passwordValidation: null,
        capitalLetters: null,
        passwordNumber: null,
        passwordSimbol: null,
        LowerCase: null,
    };
    return new Promise((resolve, reject) => {
        validations.passwordValidation = zxcvbn(password).score;
        (password.match(/[A-Z]/g)) ? validations.capitalLetters = true : validations.capitalLetters = false;
        (password.match(/[a-z]/g)) ? validations.LowerCase = true : validations.LowerCase = false;
        (password.match(/[0-9]/g)) ? validations.passwordNumber = true : validations.passwordNumber = false;
        (password.match(/[@$#%&()=?¡+*{}_¬^~<>,.:;-]/g)) ? validations.passwordSimbol = true : validations.passwordSimbol = false;
        (typeof password === 'string') ? resolve(validations) : reject('error');

    })
  }
}
