import {Component, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {CustomerService} from '../customer.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../services/auth.services';
import { Config } from '../config/config';
import { User } from '../model/userModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['../styles/main.scss']
})
export class LoginPageComponent implements OnInit {

  email: string;
  password: string;
  is_register: boolean;
  imgLogin: number;
  user: User;
  loginForm: NgForm;
  constructor(private api: ApiService, private customer: CustomerService, private router: Router, private authService: AuthService) {
    this.is_register = false;
    this.user = new User();
  }
  ngOnInit() {
    this.imgLogin = Math.floor(Math.random() * (6 - 1)) + 1;
  }
  year() {
    return moment().year();
  }
  changeRegister() {
    this.is_register = !this.is_register;
  }
  login(data) {
    this.authService.login(data.value).subscribe((data: any) => {
      localStorage.setItem(Config.token, data.token);
    });
  }

}
