// libraries
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ValidationPassword } from './services/validations.services';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// componest
import {AppComponent} from './app.component';
import {LoginPageComponent} from './login-page/login-page.component';
import { RegisterComponent } from './register/register.component';

// services, config, pipe
import {NeedAuthGuard} from './auth.guard';
import { RoutersMain } from './config/routers';

// configuration translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginPageComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(RoutersMain.appRoutes),
    NgbModule
  ],
  providers: [
    TranslateService,
    NeedAuthGuard,
    ValidationPassword,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
